import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { InlineCode } from '../../components/inline-code/inline-code';
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`Being a web developer is hard. You're handed some written requirements and, if you're lucky,
a visual design. You start work on the new feature: You add HTML elements, style them with CSS,
and implement business logic with JavaScript. You need to think about usability, web security,
page speed, data management, and so on.`}</p>
    <p>{`You take a deep breath and tell yourself: `}<ItalicText mdxType="ItalicText">{`“I can do this!”`}</ItalicText></p>
    <p>{`Your project manager comes in and asks: `}<ItalicText mdxType="ItalicText">{`“The website will also be accessible, right?”`}</ItalicText>{`
You panic and start to research web accessibility. You're flooded with information. Some of it
contradicting. You ask ChatGPT for help. It shows you overcomplex code ridden with
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA`}</a>{` attributes. You sigh,
thinking: `}<ItalicText mdxType="ItalicText">{`“That's a lot of work!”`}</ItalicText></p>
    <p>{`I have good news for you: Accessibility doesn't have to be this exhausting. It can actually be
quite simple and, I dare say, beautiful. All you need to do is embrace the
`}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Web_platform"
      }}>{`web platform`}</a>{` and let it do the work!`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "66.66666666666666%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFA//EABcBAAMBAAAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAZbCrukzzUF//8QAGRABAAMBAQAAAAAAAAAAAAAAAQACEQMi/9oACAEBAAEFAtCzTw1xDZyy1VGf/8QAFREBAQAAAAAAAAAAAAAAAAAAAQD/2gAIAQMBAT8BJb//xAAWEQEBAQAAAAAAAAAAAAAAAAAAARL/2gAIAQIBAT8BrL//xAAcEAACAQUBAAAAAAAAAAAAAAAAAQIQERIhMVH/2gAIAQEABj8CbEvd7OUcpK+MTh//xAAcEAADAAEFAAAAAAAAAAAAAAAAASERMUFhgdH/2gAIAQEAAT8hai30hgRuAQGojLhMt4j0MvZ//9oADAMBAAIAAwAAABCIL//EABgRAQEAAwAAAAAAAAAAAAAAAAEAESEx/9oACAEDAQE/ENusI4Bv/8QAGBEAAwEBAAAAAAAAAAAAAAAAABEhATH/2gAIAQIBAT8Q6g+1n//EAB0QAQEAAgIDAQAAAAAAAAAAAAERACFBcVGB0fD/2gAIAQEAAT8Q2wQtaVw5kAh1rqk4x0oe/uE0bjepMFosU+ROssFU/eM//9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Lines of code are projected onto a woman who's looking at the camera.",
          "title": "Lines of code are projected onto a woman who's looking at the camera.",
          "src": "/static/470253b68565527bb5cfb191581b60a0/e5166/pexels-thisisengineering-code-woman.jpg",
          "srcSet": ["/static/470253b68565527bb5cfb191581b60a0/f93b5/pexels-thisisengineering-code-woman.jpg 300w", "/static/470253b68565527bb5cfb191581b60a0/b4294/pexels-thisisengineering-code-woman.jpg 600w", "/static/470253b68565527bb5cfb191581b60a0/e5166/pexels-thisisengineering-code-woman.jpg 1200w", "/static/470253b68565527bb5cfb191581b60a0/b17f8/pexels-thisisengineering-code-woman.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © ThisIsEngineering / pexels.com`}</em></p>
    <p>{`We'll take a quick look at the basic tools of the web and how to use them efficiently. Then I'll
give you several specific examples of web features with accessibility baked in.`}</p>
    <h2>{`The Basic Tools of Accessibility Engineering`}</h2>
    <p>{`The web platform is a collection of technologies developed as open standards by the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/"
      }}>{`World Wide Web Consortium`}</a>{` and other standardization bodies. These technologies
include HTML, CSS, JavaScript and WAI-ARIA. These building blocks of the web enable us to design websites
that are accessible to all people.`}</p>
    <p>{`Then why are so many web developers struggling with accessibility? I think there are two main reasons:`}</p>
    <ul>
      <li parentName="ul">{`Over-reliance on external libraries.`}</li>
      <li parentName="ul">{`Insufficient experience with native web features.`}</li>
    </ul>
    <p>{`Most junior developers nowadays learn web development based on frameworks like `}<a parentName="p" {...{
        "href": "https://angular.dev/"
      }}>{`Angular`}</a>{`,
`}<a parentName="p" {...{
        "href": "https://react.dev/"
      }}>{`React`}</a>{` or `}<a parentName="p" {...{
        "href": "https://vuejs.org/"
      }}>{`Vue.js`}</a>{`. They learn everything about components and
data management, but hardly familiarize themselves with native HTML elements and advanced CSS features.`}</p>
    <p>{`I don't blame any of them! Modern JavaScript frameworks and UI libraries offer powerful features that help
us implement complex web applications. But they are another level of abstraction. They take us away from
the essential building blocks of the web. So what should you do?`}</p>
    <h3>{`HTML First and The Rule of Least Power`}</h3>
    <p>{`The `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/Rule_of_least_power"
      }}>{`rule of least power`}</a>{` is a design principle that
suggests choosing the least powerful language suitable for a given purpose. What does this mean for web
developers? If you can do something with HTML, use HTML. Need to adapt the styling? Use CSS. If you can't
do it with HTML and CSS alone, use JavaScript and `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/standards-guidelines/aria/"
      }}>{`WAI-ARIA`}</a>{`.`}</p>
    <p>{`Native HTML elements are built to work on multiple browsers and with different types of assistive technology,
like screen readers. They're usually more robust and reliable than custom solutions. Next time you start to
implement a new feature, look for a solution in the following order:`}</p>
    <ol>
      <li parentName="ol">{`HTML`}</li>
      <li parentName="ol">{`CSS`}</li>
      <li parentName="ol">{`JavaScript`}</li>
      <li parentName="ol">{`WAI-ARIA`}</li>
      <li parentName="ol">{`External Libraries`}</li>
    </ol>
    <p>{`As a first step, I always
search for a native solution on `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/"
      }}>{`MDN Web Docs`}</a>{`. If I can't do something with
HTML and CSS alone, I start to research the proper use of ARIA attributes and JavaScript to create an accessible
solution. The `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/"
      }}>{`ARIA Authoring Practices Guide (APG)`}</a>{` provides all the
information you need.`}</p>
    <p>{`Why are external libraries last on the list? Because they create an additional dependency for your project.
Once you've commited to using a UI component library, you're usually stuck with it. That's why you should
make sure to pick an accessible UI library. Go read my
`}<a parentName="p" {...{
        "href": "/accessible-ui-component-libraries.en"
      }}>{`“Checklist for Accessible UI Component Libraries”`}</a>{`.`}</p>
    <h3>{`The Current State of the Web`}</h3>
    <p>{`The web platform is constantly evolving. New HTML elements, CSS properties and Web APIs are specified and
implemented by browsers every day. Initiatives like `}<a parentName="p" {...{
        "href": "https://wpt.fyi/interop-2024"
      }}>{`Interop 2024`}</a>{` help to
improve the interoperability of the web.`}</p>
    <p>{`For web developers, it's way easier and straightforward to create accessible websites today than it was
10 years ago. I admit, not everything can be done with HTML and CSS alone. Right now, you still need ARIA
and JavaScript to implement a `}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tabs/"
      }}>{`tab list`}</a>{` or
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/ARIA/apg/patterns/tooltip/"
      }}>{`custom tooltips`}</a>{`.`}</p>
    <p>{`But I want to focus on the bright side! Let's take a look at some native HTML features that make it very
easy and convenient to create robust, accessible websites.`}</p>
    <h2>{`Examples of Native HTML Features`}</h2>
    <h3>{`The `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element: A Powerful Allrounder`}</h3>
    <p>{`Chances are, the `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element is older than you! It was first introduced
in the `}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/REC-html40-971218/"
      }}>{`HTML 4.0 specification`}</a>{` in December 1997. This means,
buttons have been around for quite a while. But still, many web developers underestimate this powerful
and versatile HTML element. Some even think it's a good idea to use a `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element
instead. The horror! 😱`}</p>
    <p>{`Maybe you're thinking: `}<ItalicText mdxType="ItalicText">{`“Alright, Romeo! Why are you so in love with the button element?”`}</ItalicText>{`
Let me answer with the `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/HTML/Element/button"
      }}>{`short description by MDN`}</a>{`:`}</p>
    <blockquote>
      <p parentName="blockquote">{`The `}{`<`}{`button`}{`>`}{` HTML element is an interactive element activated by a user with a mouse, keyboard, finger,
voice command, or other assistive technology. Once activated, it then performs an action, such as submitting
a form or opening a dialog.`}</p>
    </blockquote>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element was designed to work well for all users. You can click on it
with a mouse. You can focus a button with the `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{` key and activate it with
the `}<InlineCode mdxType="InlineCode">{`Enter`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`Space`}</InlineCode>{` key. When you navigate to the button
using a screen reader, it will be able to tell you that this is a button that can be activated.`}</p>
    <p>{`Browsers also apply a default styling to HTML buttons. Some web developers might find this annoying,
because they have to override these styles with CSS. They might pick the `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element
instead and slap a `}<InlineCode mdxType="InlineCode">{`click`}</InlineCode>{` event handler on it, thinking that's enough. Well, it's
not!`}</p>
    <p>{`There's a big difference between `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` elements!
A button will trigger the `}<InlineCode mdxType="InlineCode">{`click`}</InlineCode>{` event handler for all types of user interaction:
mouse, keyboard, screen reader, and so on. The `}<InlineCode mdxType="InlineCode">{`div`}</InlineCode>{` element, on the other hand, will
only trigger on mouse or touch interaction.
To `}<a parentName="p" {...{
        "href": "https://theadminbar.com/accessibility-weekly/divs-are-not-buttons/"
      }}>{`turn a div into an accessible button`}</a>{`,
you need to:`}</p>
    <ul>
      <li parentName="ul">{`Add a `}<InlineCode mdxType="InlineCode">{`tabindex`}</InlineCode>{` to make it keyboard focusable.`}</li>
      <li parentName="ul">{`Show a visible focus indicator when the button receives focus.`}</li>
      <li parentName="ul">{`Add key event handlers for the `}<InlineCode mdxType="InlineCode">{`Enter`}</InlineCode>{` and `}<InlineCode mdxType="InlineCode">{`Space`}</InlineCode>{` key.`}</li>
      <li parentName="ul">{`Add a `}<InlineCode mdxType="InlineCode">{`role`}</InlineCode>{` attribute to tell screen reader users it's a button.`}</li>
    </ul>
    <p>{`Wow, that's a lot of work and code bloat! An implementation using a native button instead will always be
easier to read and maintain. If you're still not convinced, go read my article
`}<a parentName="p" {...{
        "href": "/3-ways-web-devs-benefit-accessibility.en/"
      }}>{`“3 Ways how Web Developers also benefit from Accessibility”`}</a>{`.`}</p>
    <p>{`That's already a lot of text about buttons. But there's one more argument I'd like to make: Native buttons
are still gaining more superpowers!`}</p>
    <p>{`The latest upgrade is thanks to
the new `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Popover_API"
      }}>{`Popover API`}</a>{`. You can use
the `}<InlineCode mdxType="InlineCode">{`popovertarget`}</InlineCode>{` attribute to turn the `}<InlineCode mdxType="InlineCode">{`button`}</InlineCode>{` element
into a popover control button. Check out this menu button demo (currently only works in Chromium browsers):`}</p>
    <iframe title="Accessible Menu with Popover API" src="https://codepen.io/alexlehner86/embed/rNQmERq?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/rNQmERq">
    Accessible Menu with Popover API</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`There are so many use cases for buttons with attached popovers: Menus, tooltips, date pickers, etc.
If you're new to the topic, you can take a look at the following articles I've written:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/popover-api-accessibility.en/"
        }}>{`“Make your content pop with the Popover API and CSS Anchor Positioning”`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/accessible-navigation-menu.en/"
        }}>{`“How to build Accessible Navigation Menus with the Popover API”`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/native-dialog-and-popover.en/"
        }}>{`“Native Dialogs and the Popover API — What you need to know”`}</a></li>
    </ul>
    <p>{`But we're not done yet! The next button upgrade will arrive with the (currently experimental)
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/API/Invoker_Commands_API"
      }}>{`Invoker Commands API`}</a>{`.
This API provides a way to declaratively assign behaviors to buttons, like opening or closing a modal
dialog. Awesome! 🥰`}</p>
    <p>{`Oh, what a coincidence! Dialogs are the next native HTML feature I want to talk about.`}</p>
    <h3>{`The `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element: Always on top`}</h3>
    <p>{`Dialogs are an integral part of modern web user interfaces: From simple prompts for confirming an action
to content-heavy windows. The native `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element makes it super easy to
open an accessible modal dialog on top of the other page content.`}</p>
    <iframe src="https://alexlehner86.github.io/fancy-css-playground/#/dialog" title="Demo Native Dialog Element" loading="lazy"></iframe>
    <p>{`The `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element is accessible by default. When you use
the `}<InlineCode mdxType="InlineCode">{`showModal()`}</InlineCode>{` method to open a modal dialog, the following happens:`}</p>
    <ul>
      <li parentName="ul">{`The browser automatically moves focus to the first interactive element inside of the dialog.`}</li>
      <li parentName="ul">{`A screen reader will announce that the new content is part of a dialog.`}</li>
      <li parentName="ul">{`While the modal dialog is active, the content obscured by the dialog is inaccessible to all users. This
means that keyboard users can't leave the dialog with the `}<InlineCode mdxType="InlineCode">{`TAB`}</InlineCode>{` key, and a screen
reader's virtual cursor is not allowed to leave the modal dialog as long as it remains open.`}</li>
      <li parentName="ul">{`Users can close the modal dialog with the `}<InlineCode mdxType="InlineCode">{`ESC`}</InlineCode>{` key.`}</li>
      <li parentName="ul">{`On close, keyboard focus returns to the control that initially activated the dialog. This allows
keyboard and screen reader users to continue browsing from where they left off.`}</li>
    </ul>
    <p>{`Of course, you don't have to use the native `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element. You could also
implement a custom, accessible dialog using ARIA and JavaScript. But why would you? Come on! Be a smart
web developer and let the web platform do the work. 😉`}</p>
    <p>{`If you're unfamiliar with the native `}<InlineCode mdxType="InlineCode">{`dialog`}</InlineCode>{` element and want to know more, go
read my article `}<a parentName="p" {...{
        "href": "/native-dialog-element.en/"
      }}>{`“Why you should use the Native Dialog Element”`}</a>{`.`}</p>
    <h3>{`Form Controls: The Good and the Bad`}</h3>
    <p>{`Far too often, web developers use custom form controls to meet design needs – neglecting accessibility
in the process. You can do better! Modern browsers enable us to apply custom styling to most
`}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn_web_development/Extensions/Forms/Basic_native_form_controls"
      }}>{`native form controls`}</a>{`.
This way, we can create accessible web forms with a consistent look across all platforms.`}</p>
    <p>{`Native form controls like `}<InlineCode mdxType="InlineCode">{`input`}</InlineCode>{` or `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` are accessible
by default. They receive keyboard focus and can be activated with `}<InlineCode mdxType="InlineCode">{`Enter`}</InlineCode>{`
or `}<InlineCode mdxType="InlineCode">{`Space`}</InlineCode>{`, e.g., to mark a checkbox as checked. They communicate their role and
current state to screen readers.`}</p>
    <p>{`The following demo shows an accessible web form with custom styles for the native select list and
radio buttons:`}</p>
    <iframe title="Styling Native Form Controls" src="https://codepen.io/alexlehner86/embed/mdqGLxZ?default-tab=result" loading="lazy">
    See the Pen <a href="https://codepen.io/alexlehner86/pen/mdqGLxZ">
    Styling Native Form Controls</a> by Alexander Lehner (<a href="https://codepen.io/alexlehner86">@alexlehner86</a>)
    on <a href="https://codepen.io">CodePen</a>.
    </iframe>
    <p>{`Unfortunately, not all native form controls can be easily adapted with custom styles. Mozilla has a great
article on `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Learn_web_development/Extensions/Forms/Styling_web_forms"
      }}>{`Styling web forms`}</a>{`,
which I highly recommend. They categorize native form controls into three groups: “The good”, “The bad”,
and “The ugly”.`}</p>
    <p>{`In my opinion, the native elements for text inputs, radio buttons, checkboxes and simple select lists are
very easy to style. There is no reason to use custom elements instead. Unless you have a masochistic disposition.
Read my article `}<a parentName="p" {...{
        "href": "/styling-accessible-web-forms.en/"
      }}>{`“Create Accessible Web Forms with Custom Styling”`}</a>{` for
more information.`}</p>
    <p>{`Now, what about the rest? Several native form controls have internals that can't be styled in CSS alone. These
include the color picker, date picker, file picker, and the drop-down list of the `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{`
element. In these cases, I understand the need for custom components. Just make sure they're accessible.`}</p>
    <p>{`Lucky for us, the `}<InlineCode mdxType="InlineCode">{`select`}</InlineCode>{` element will soon get an important upgrade. A customizable
version of the element is officially in `}<a parentName="p" {...{
        "href": "https://github.com/whatwg/html/issues/9799"
      }}>{`Stage 2 in the WHATWG`}</a>{`,
with strong cross-browser interest and a prototype available in Chrome Canary. Read this
`}<a parentName="p" {...{
        "href": "https://developer.chrome.com/blog/rfc-customizable-select"
      }}>{`Chrome blog post`}</a>{` for more information. 🤩`}</p>
    <h3>{`Disclosure and accordion widgets with native HTML`}</h3>
    <p>{`The native `}<InlineCode mdxType="InlineCode">{`details`}</InlineCode>{` HTML element creates a disclosure widget in which information
is visible only when the widget is toggled open. The content of the nested `}<InlineCode mdxType="InlineCode">{`summary`}</InlineCode>{`
element provides the label for the disclosure widget. The perfect HTML elements for a FAQ page or an
expandable info section in an article.`}</p>
    <deckgo-highlight-code {...{
      "terminal": "carbon",
      "theme": "dracula"
    }}>{`
          `}<code parentName="deckgo-highlight-code" {...{
        "slot": "code"
      }}>{`<details>
    <summary>What is HTML?</summary>
    HTML is the standard markup language for web pages, defined by the W3C and WHATWG.
</details>`}</code>{`
        `}</deckgo-highlight-code>
    <p>{`Historically it wasn't possible to change the `}<InlineCode mdxType="InlineCode">{`display`}</InlineCode>{` type of
the `}<InlineCode mdxType="InlineCode">{`details`}</InlineCode>{` element. This restriction has already been relaxed in Chromium browsers,
allowing you to use grid or flex layouts.
The article `}<a parentName="p" {...{
        "href": "https://developer.chrome.com/blog/styling-details"
      }}>{`“More options for styling `}{`<`}{`details`}{`>`}{`”`}</a>{`
includes a really cool demo for an exclusive accordion.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`Alright. This article turned out longer than I had intended. 😂`}</p>
    <p>{`I hope it helps you on your journey to becoming an accessibility engineer. Native HTML is powerful and
(mostly) accessible by default. If you keep this in mind, you'll be alright.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      